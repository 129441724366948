const filterModule = (function () {
	const
		checkbox = $('.filter-checkbox input'),
		checkboxInfo = $('.filter-checkbox__info'),
		rangeInput = $('[data-range]'),
		rangeInputContainer = $('.filter-range__input');

	let checkBoxValue = 0;
	let isInfoShow = 0;

	function init() {
		_setUpListeners();

		$('.filter-select select').SumoSelect({
			captionFormat: '{0}',
			captionFormatAllSelected: 'Выбраны все'
		});

		$('.filter-select').width($('.filter-select').width());
	}

	function _setUpListeners() {
		checkbox.on('change', checkboxHandler);
		rangeInput.on('change', checkRange);
		rangeInput.on('focus', function () {
			rangeInputContainer.removeClass('is-error');
		});
	}

	function checkboxHandler() {
		const checkboxCurrent = $(this).closest('.filter-checkbox');
		this.value = checkBoxValue = !checkBoxValue;

		checkboxCurrent.toggleClass('is-checked');
	}
	
	function checkRange() {
		const currentValue = +$(this).val();
		const inputContainer = $(this).closest('.filter-range__input');
		const defaultValues = $(this).data('range');

		if (currentValue) {
			if (currentValue < defaultValues[0] || currentValue > defaultValues[1])
				inputContainer.addClass('is-error');
			else
				inputContainer.removeClass('is-error');
		} else {
			inputContainer.removeClass('is-error');
		}
	}

	return {
		init: init
	}
})();

filterModule.init();

const filterModuleMobile = (function () {
	const
		// checkbox = $('.filter-checkbox input'),
		form = $('.filter form'),
		closeBtn = $('.filter__close'),
		filter = $('.filter'),
		checkboxBlockTrigger = $('.filter-block_checkbox'),
		checkboxBlock = $('.filter-block__checkbox-list'),
		infoTrigger = $('.filter-checkbox__info'),
		openBtn = $('.filter-open');

	function init() {
		_setUpListeners();
	}

	function _setUpListeners() {
		openBtn.on('click', showFilter);
		closeBtn.on('click', hideFilter);
		checkboxBlockTrigger.on('click', showCheckboxBlock);
		infoTrigger.on('click', showInfoBlock);
		$('.filter-checkbox__text-inner').on('click', hideCheckboxBlock);
	}

	function hideCheckboxBlock() {
		$(this).closest('.filter-checkbox__text').removeClass('is-active');
	}
	
	function showCheckboxBlock() {
		checkboxBlock.hide();

		if ($(this).hasClass('is-active')) {
			checkboxBlockTrigger.removeClass('is-active');
			$(this).removeClass('is-active');
			$(this).find(checkboxBlock).hide();
		} else {
			checkboxBlockTrigger.removeClass('is-active');
			$(this).addClass('is-active');
			$(this).find(checkboxBlock).show();
		}
	}

	function showInfoBlock() {
		$(this).closest('.filter-checkbox__text').addClass('is-active');
	}

	function showFilter() {
		$(this).addClass('is-active');
		$('body').addClass('is-overflow-hidden');
		filter.show();
	}

	function hideFilter() {
		openBtn.removeClass('is-active');
		$('body').removeClass('is-overflow-hidden');
		filter.hide();

		checkForm();
	}

	function checkForm() {
		const selectValue = form.find('select').val();
		const checkboxValue = form.find('[type="checkbox"]').val();
		const rangeValue = form.find('[type="text"]').val();

		console.log(selectValue)
		console.log(checkboxValue)
		console.log(rangeValue)
	}

	return {
		init: init
	}
})();

if ($(window).width() <= 768) {
	filterModuleMobile.init();
}
